<template>
  <div class="upload-file">
    <p class="form-title form-title2">
      1.下载模板，并根据模板中的要求完善要导入的文件
    </p>
    <section class="button-box">
      <el-button
        type="primary"
        size="small"
        @click="onExport"
        :loading="downLoadStatus"
        icon="el-icon-download"
        >点击下载导入模板</el-button
      >
    </section>

    <p class="form-title form-title2">2.请选择要导入的车辆{{ companyName }}</p>
    <el-form
      ref="addForm"
      :model="addForm"
      :inline="true"
      size="small"
      :rules="formRules"
      class="dialog-form"
    >
      <!-- 公司车队： -->
      <el-form-item :label="`${companyName}：`" prop="companyId">
        <carGroupSelect @getData="getGroupId" v-if="type === 0" />
        <companySelectForSearch
          ref="companySelectForSearch"
          :multiple="false"
          :searchable="true"
          @getValue="getGroupIds"
          v-else
        />
      </el-form-item>
    </el-form>

    <p class="form-title form-title2">3.上传导入文件</p>
    <section class="button-box">
      <el-upload
        ref="upload"
        class="upload"
        :multiple="false"
        :auto-upload="true"
        :on-error="uploadFalse"
        :on-success="uploadSuccess"
        :before-upload="beforeUpload"
        :data="addForm"
        :action="uploadUrl()"
        :headers="getHeader()"
        :limit="1"
      >
        <el-button type="primary" size="small" icon="el-icon-upload"
          >{{ name }}
        </el-button>
      </el-upload>
    </section>
    <section class="button-box">
      <p>备注：1.{{ remarks[type].split('_')[0] }}</p>
      <p>
        {{
          remarks[type].split('_')[1] ? `2. ${remarks[type].split('_')[1]}` : ''
        }}
      </p>
    </section>
  </div>
</template>
<script>
import companySelectForSearch from '@/components/treeSelect/companySelectForSearch.vue'
import carGroupSelect from '@/components/treeSelect/carGroupSelect.vue'

import config from '@/api/static/config'
import { addExcelExport } from '@/api/lib/refreshAlarm.js'
import { getCurent } from '@/common/utils/index.js'
export default {
  components: {
    companySelectForSearch,
    carGroupSelect
  },
  props: {
    /** 车辆0|驾驶员1|终端2 */
    type: {
      type: Number,
      default: 0
    },
    /** 导入的请求路径 */
    url: {
      type: String,
      default: ''
    },
    /** 按钮名称 */
    name: {
      type: String,
      default: '导入'
    }
  },
  computed: {
    companyName: function (params) {
      return this.type === 0 ? '所属车队' : '所属企业'
    }
  },

  data() {
    return {
      addForm: {
        companyId: null
      },
      formRules: {
        companyId: [
          {
            required: true,
            message: '请选择',
            trigger: 'change'
          }
        ]
      },
      hint: ['请选择车队', '请选择公司', '请选择公司'],
      remarks: [
        '多车队的车辆请拆分多个文件导入。_默认导入的车辆状态均为正常，其它状态请导入后修改',
        '多公司的驾驶员请拆分多个文件导入。_默认导入的驾驶员状态均为正常，其它状态请导入后修改',
        '多公司的终端请拆分多个文件导入。_'
      ],
      downLoadStatus: false
    }
  },

  methods: {
    // 获取公司数据
    getGroupIds(val) {
      this.$refs.addForm.clearValidate()
      this.addForm.companyId = val.length === 0 ? null : val[0]
    },
    // 获取车组数据
    getGroupId(val) {
      this.$refs.addForm.clearValidate()
      this.addForm.companyId = val
    },

    // 下载模板
    onExport() {
      this.downLoadStatus = true
      let data = {
        baseUrl: 'base',
        queryParam: ''
      }
      switch (this.type) {
        case 0:
          data.fileName = `车辆导入模板-${getCurent()}`
          data.queryPath = '/vehicle/importVehicleTemplateExport'
          break
        case 1:
          data.fileName = `驾驶员导入模板-${getCurent()}`
          data.queryPath = '/driver/importDriverTemplateExport'
          break
        case 2:
          ;(data.fileName = `终端导入模板-${getCurent()}`),
            (data.queryPath = '/device/importDeviceTemplateExport')
          break

        default:
          break
      }
      addExcelExport(data)
        .then((res) => {
          this.$message.success(res.msg)
          this.downLoadStatus = false
          this.$store.dispatch('setRefreshStatus', true)
        })
        .catch(() => {
          this.downLoadStatus = false
        })
    },
    getHeader() {
      return {
        Authorization: sessionStorage.getItem('token'),
        token: sessionStorage.getItem('token')
      }
    },

    beforeUpload(file) {
      this.$refs.addForm.validate((valid) => {
        if (valid) {
        } else {
          this.$refs.upload.clearFiles()
          this.$forceUpdate()
          return false
        }
      })
      // let checkMsg = this.check_file(file);
      const checkMsg = ''
      if (checkMsg !== '') {
        this.$message.error(checkMsg)
      }
    },
    check_file(file) {
      let msg = ''
      const extension = file.name.split('.')[1] === 'xls'
      const extension2 = file.name.split('.')[1] === 'xlsx'
      const isLt2M = file.size / 1024 / 1024 < 10
      if (!extension && !extension2) {
        msg = '只能上传xls或xlsx文件'
      }
      if (!isLt2M) {
        msg = '上传模板大小不能超过 10MB!'
      }
      return msg
    },

    /* 上传接口 */
    uploadUrl() {
      return config.apiBaseUrl + this.url
    },

    /* 添加文件成功时触发函数 上传到后台 */
    uploadSuccess(response, file, fileList) {
      if (response.code === 1000) {
        this.$message.success(response.data)
        this.$emit('uploadSuccess')
      } else {
        this.$message.error(response.data || response.msg)
        this.$refs.upload.clearFiles()
        this.$forceUpdate()
      }
    },
    /* 添加文件失败 */
    uploadFalse(response, file, fileList) {
      this.$message.error('文件上传失败！')
      this.$refs.upload.clearFiles()
      this.$forceUpdate()
    }
  },
  mounted() {}
}
</script>
<style lang="scss" scoped>
.upload-file {
  @include themify() {
    .button-box {
      padding: 1.5vh 3vw;
      p {
        height: 20px;
        line-height: 20px;
      }
      p:nth-of-type(2) {
        text-indent: 3rem;
      }
    }
    .form-title {
      font-size: 14px;
      color: themed('n7');
      margin: 10px 0;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .el-button {
        margin-left: 20px;
      }
    }
    .el-form-item__content {
      i {
        margin-left: 2px;
      }
    }
  }
}
</style>
